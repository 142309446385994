export const ERROR_VIEW_CODE = { // eslint-disable-line import/prefer-default-export
    GENERIC: 0,
    SERVICE_FAILURE: 1,
    UNREACHABLE_SITE: 2,
    BLOCKED_SITE: 3,
    TIMED_OUT_SITE: 4,
    BAD_CLIENT_BROWSER_LOCATION: 5,
    CLOSED_BY_NATURAL_CAUSE: 6,
    BLOCKED_UNSAFE: 7,
    NOT_WEBMAIL: 8,
    DOCUMENT_RENDER_ERROR: 9,
    DOWNLOAD_DISABLED: 10,
    NEVER_ISOLATE: 12,
    RESERVING_BROWSER: 13,
    NEVER_ISOLATE_INSTANT: 14,
    EMPTY_PAGE_CONTENT: 15,
};

export const CONTROL_MODE = {
    AUDIT: 'audit'
};

export const POLICY_MIGRATION_STEP_ENUM = {
    OLD_ONLY: 'OLD_ONLY',
    NEW_IN_TEST: 'NEW_IN_TEST',
    OLD_INACTIVE: 'OLD_INACTIVE',
    NEW_ONLY: 'NEW_ONLY'
};

export const REV_TYPE = {
    RULE: 'rule',
    BROWSING_ROLE: 'BrowsingRole',
    PRIORITY_CHANGED: 'PRIORITY_CHANGED',
    USER_GROUP_SOURCE_CHANGED: 'USER_GROUP_SOURCE_CHANGED',
};

export const RULE_REC_TYPE = {
    CONTENT_RULES: 'ContentRule',
    EXCLUSION_RULES: 'ExclusionRule',
    REDIRECT_RULES: 'RedirectRule',
    FILE_THREAT_RULES: 'FileThreatRule',
    DLP_RULES: 'DLPRule',
    CUSTOM_CATEGORIES: 'CustomerCategory',
};

// Each item represents a granular action that a user is permitted to take.
export const PERMISSIONS = {
    BROWSING_ROLE_CREATE: 'BROWSING_ROLE_CREATE',
    BROWSING_ROLE_DELETE: 'BROWSING_ROLE_DELETE',
    BROWSING_ROLE_MODIFY: 'BROWSING_ROLE_MODIFY',
    BROWSING_ROLE_LIST: 'BROWSING_ROLE_LIST',
    CONTENT_RULE_CREATE: 'CONTENT_RULE_CREATE',
    CONTENT_RULE_DELETE: 'CONTENT_RULE_DELETE',
    CONTENT_RULE_MODIFY: 'CONTENT_RULE_MODIFY',
    CONTENT_RULE_LIST: 'CONTENT_RULE_LIST',
    DLP_RULE_CREATE: 'DLP_RULE_CREATE',
    DLP_RULE_DELETE: 'DLP_RULE_DELETE',
    DLP_RULE_MODIFY: 'DLP_RULE_MODIFY',
    DLP_RULE_LIST: 'DLP_RULE_LIST',
    EXCLUSION_RULE_CREATE: 'EXCLUSION_RULE_CREATE',
    EXCLUSION_RULE_DELETE: 'EXCLUSION_RULE_DELETE',
    EXCLUSION_RULE_MODIFY: 'EXCLUSION_RULE_MODIFY',
    EXCLUSION_RULE_LIST: 'EXCLUSION_RULE_LIST',
    FILE_THREAT_RULE_CREATE: 'FILE_THREAT_RULE_CREATE',
    FILE_THREAT_RULE_DELETE: 'FILE_THREAT_RULE_DELETE',
    FILE_THREAT_RULE_MODIFY: 'FILE_THREAT_RULE_MODIFY',
    FILE_THREAT_RULE_LIST: 'FILE_THREAT_RULE_LIST',
    REDIRECT_RULE_CREATE: 'REDIRECT_RULE_CREATE',
    REDIRECT_RULE_DELETE: 'REDIRECT_RULE_DELETE',
    REDIRECT_RULE_MODIFY: 'REDIRECT_RULE_MODIFY',
    REDIRECT_RULE_LIST: 'REDIRECT_RULE_LIST',
    DETECTOR_SETS_LIST: 'DETECTOR_SETS_LIST',
    FILE_TYPES_LIST: 'FILE_TYPES_LIST',
    STANDARD_CATEGORY_LIST: 'STANDARD_CATEGORY_LIST',
    CUSTOM_CATEGORY_LIST: 'CUSTOM_CATEGORY_LIST',
    CUSTOM_CATEGORY_CREATE: 'CUSTOM_CATEGORY_CREATE',
    CUSTOM_CATEGORY_DELETE: 'CUSTOM_CATEGORY_DELETE',
    CUSTOM_CATEGORY_GET: 'CUSTOM_CATEGORY_GET',
    CUSTOM_CATEGORY_MODIFY: 'CUSTOM_CATEGORY_MODIFY',
    CUSTOM_EMAIL_LIST: 'CUSTOM_EMAIL_LIST',
    CUSTOM_DOMAIN_LIST: 'CUSTOM_DOMAIN_LIST',
    USER_GROUP_LIST: 'USER_GROUP_LIST',
    POLICY_TESTING_EVALUATION_CREATE: 'POLICY_TESTING_EVALUATION_CREATE',
    ACTIVITY_GET: 'ACTIVITY_GET',
    ACTIVITY_MODIFY: 'ACTIVITY_MODIFY',
    ACTIVITY_DELETE: 'ACTIVITY_DELETE',
    AUDIT_LOG_GET: 'AUDIT_LOG_GET',
    ASSOCIATED_EMAIL_DOMAIN_SET_MODIFY: 'ASSOCIATED_EMAIL_DOMAIN_SET_MODIFY',
    ASSOCIATED_EMAIL_DOMAIN_SET_GET: 'ASSOCIATED_EMAIL_DOMAIN_SET_GET',
    ASSOCIATED_IP_RANGE_SET_MODIFY: 'ASSOCIATED_IP_RANGE_SET_MODIFY',
    ASSOCIATED_IP_RANGE_SET_GET: 'ASSOCIATED_IP_RANGE_SET_GET',
    APPEARANCE_MODIFY: 'APPEARANCE_MODIFY',
    APPEARANCE_GET: 'APPEARANCE_GET',
    CUSTOMIZE_UI_GET: 'CUSTOMIZE_UI_GET',
    PPT_CREATE: 'PPT_CREATE',
    DASHBOARD_GET: 'DASHBOARD_GET',
    BROWSING_POLICY_CREATE: 'BROWSING_POLICY_CREATE',
    BROWSING_POLICY_DELETE: 'BROWSING_POLICY_DELETE',
    BROWSING_POLICY_MODIFY: 'BROWSING_POLICY_MODIFY',
    BROWSING_POLICY_LIST: 'BROWSING_POLICY_LIST',
    CUSTOMER_SETTINGS_GET: 'CUSTOMER_SETTINGS_GET',
    CUSTOMER_SETTINGS_MODIFY: 'CUSTOMER_SETTINGS_MODIFY',
    GEOLOCATION_GET: 'GEOLOCATION_GET',
    CUSTOMER_POLICY_CREATE: 'CUSTOMER_POLICY_CREATE',
    CUSTOMER_POLICY_DELETE: 'CUSTOMER_POLICY_DELETE',
    CUSTOMER_POLICY_MODIFY: 'CUSTOMER_POLICY_MODIFY',
    CUSTOMER_POLICY_LIST: 'CUSTOMER_POLICY_LIST',
    POLICY_ACCESS_CODE_LIST: 'POLICY_ACCESS_CODE_LIST',
    POLICY_ACCESS_CODE_CREATE: 'POLICY_ACCESS_CODE_CREATE',
    POLICY_ACCESS_CODE_MODIFY: 'POLICY_ACCESS_CODE_MODIFY',
    POLICY_ACCESS_CODE_DELETE: 'POLICY_ACCESS_CODE_DELETE',
};
